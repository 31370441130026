import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import PostPreview from "../components/PostPreview"
import ArchivePagination from "../components/ArchivePagination"

const Archive = (props) => {
  const {
    data: {
      allWpPost: { nodes, pageInfo },
      wpCategory: { language, translations },
    },
    pageContext: { archiveType, archivePath, categoryName, categorySlug },
  } = props

  // console.log(props.location)

  // state handling click on PostPreview to animate closing current and opening new Post
  const [newPostId, setNewPostId] = useState()

  return (
    <Layout
      location={props.location}
      language={language}
      translations={translations}
      bodyClass={`archive ${archiveType} category-${categorySlug} blog wp-embed-responsive footer-top-visible customize-support`}
    >
      <Seo title={categoryName} description="" lang={language.slug} />

      <header className="archive-header header-footer-group page-header">
        <div className="archive-header-inner section-inner medium page-header-inner">
          <h1 className="archive-title color-accent">{categoryName}</h1>
        </div>
      </header>

      {nodes &&
        nodes.map((post, index) => {
          return (
            <PostPreview
              key={index}
              post={post}
              isLast={index === nodes.length - 1}
              isNewYear={index === 0 || post.year !== nodes[index - 1].year}
              setNewPostId={setNewPostId}
              contentType="archive"
            />
          )
        })}

      <ArchivePagination {...pageInfo} archivePath={archivePath} />
    </Layout>
  )
}

export const query = graphql`
  query ArchivePage(
    $offset: Int!
    $perPage: Int!
    $userDatabaseId: Int
    $categoryDatabaseId: Int
  ) {
    allWpPost(
      limit: $perPage
      skip: $offset
      filter: {
        author: { node: { databaseId: { eq: $userDatabaseId } } }
        categories: {
          nodes: { elemMatch: { databaseId: { eq: $categoryDatabaseId } } }
        }
      }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        ...PostPreviewContent
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
    }
    wpCategory(databaseId: { eq: $categoryDatabaseId }) {
      language {
        slug
        id
      }
      translations {
        id
        databaseId
        uri
        language {
          slug
          id
        }
      }
    }
  }
`

export default Archive
